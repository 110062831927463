import React, { useState, useEffect } from 'react';
import CollaboratorList from './collaboration/CollaboratorList';
import './DemoComponentCard.css';
import InstanceSettings from './InstanceSettings';
import flagsmith from 'flagsmith';
import { Button, Dialog, Tooltip, Link } from '@okta/odyssey-react-mui';
import AppsIcon from '@mui/icons-material/Apps';
import WebhookIcon from '@mui/icons-material/Webhook';
import { hasUserFacingSetting } from '../util/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';
const DemoComponentCard = ({ component, waiting, attachEvent }) => {
  const [instanceSettings, setInstanceSettings] = useState({});
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  function attachResource(componentId) {
    attachEvent(componentId, instanceSettings);
  }

  async function handleDialogOpen() {
    var personalSettings = [];
    if (component.settings && component.settings['awsAccountId']) {
      if (flagsmith.getTrait('aws_account_id')) {
        var accountId = flagsmith.getTrait('aws_account_id').toString();
        //this is needed as flagsmith forces all numberic traits to number and trims leading zeros
        if (accountId.length < 12) {
          accountId = String(accountId).padStart(12, '0');
        }
        personalSettings['awsAccountId'] = accountId;
      }
    }
    if (
      component.settings &&
      component.settings['awsRegion'] &&
      flagsmith.getTrait('aws_preferred_region')
    ) {
      personalSettings['awsRegion'] = flagsmith.getTrait(
        'aws_preferred_region'
      );
    }

    if (component.settings && component.settings['InitialInboundIpAddress']) {
      await axios.get('https://api.ipify.org?format=json').then((response) => {
        personalSettings['InitialInboundIpAddress'] = response.data.ip;
      });
    }
    setInstanceSettings({ ...personalSettings });
    setSettingsDialogOpen(true);
  }

  return (
    <div>
      {component?.resource_id ? (
        <>
          {component.category !== 'managed' && (
            <CollaboratorList entity={component} />
          )}
          <div className="buttonStyleOverflow">
            {component.description?.length > 80 ? (
              <Tooltip placement="top" text={component.description}>
                {component.description.substring(
                  0,
                  component.description.indexOf(' ', 80)
                )}{' '}
                <Button
                  variant="secondary"
                  size="small"
                  style={{ padding: 0 }}
                  startIcon={<MoreHorizIcon />}
                ></Button>
              </Tooltip>
            ) : (
              component.description
            )}
          </div>

          <div className="buttonContainerTooltip">
            <div className="docLinks">
              {component.documentationLink && (
                <Link target="_blank" href={component.documentationLink}>
                  Documentation
                </Link>
              )}
              {component.video && (
                <Link target="_blank" href={component.video}>
                  Video
                </Link>
              )}
              {component.supportLink && (
                <Link target="_blank" href={component.supportLink}>
                  Support
                </Link>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.1rem',
                marginTop: '0',
              }}
            >
              <WebhookIcon />
              <strong> Resource</strong>
            </div>
          </div>

          {component.state === 'active' || component.state === 'preview' ? (
            <div className="buttonContainerFooter">
              {hasUserFacingSetting(component.settings) ? (
                <>
                  <Button
                    className="card-action branded"
                    isabled={waiting}
                    label="Attach"
                    onClick={handleDialogOpen}
                  ></Button>
                  <Dialog
                    isOpen={settingsDialogOpen}
                    title={`${component.name} Settings`}
                    primaryCallToActionComponent={
                      <Button
                        label="Continue"
                        isabled={waiting}
                        onClick={() => attachResource(component.resource_id)}
                      />
                    }
                    secondaryCallToActionComponent={
                      <Button
                        label="Cancel"
                        onClick={() => setSettingsDialogOpen(false)}
                        variant="floating"
                      />
                    }
                    onClose={() => setSettingsDialogOpen(false)}
                  >
                    Customize the settings for this resource or proceed with the
                    default settings.
                    <InstanceSettings
                      defaultSettings={instanceSettings}
                      instanceSettings={component.settings}
                      editMode={true}
                      onUpdate={setInstanceSettings}
                    />
                  </Dialog>
                </>
              ) : (
                <Button
                  className="card-action branded"
                  isabled={waiting}
                  label="Attach"
                  onClick={() => {
                    attachEvent(component.resource_id);
                    setSettingsDialogOpen(false);
                  }}
                >
                  Attach
                </Button>
              )}
            </div>
          ) : (
            <Button
              className="card-action"
              isDisabled
              label={component.state}
            ></Button>
          )}
        </>
      ) : (
        <>
          {' '}
          <div>
            {component.category !== 'managed' && (
              <div>
                <CollaboratorList entity={component} />
              </div>
            )}

            <Link href={component.baseAddress}>
              {new URL(component.baseAddress).host}
            </Link>
          </div>
          <div className="cardContent">
            <div className="buttonStyleOverflow">
              {component.description?.length > 80 ? (
                <Tooltip placement="top" text={component.description}>
                  {component.description.substring(
                    0,
                    component.description.indexOf(' ', 80)
                  )}{' '}
                  <Button
                    variant="secondary"
                    size="small"
                    style={{ padding: 0 }}
                    startIcon={<MoreHorizIcon />}
                  ></Button>
                </Tooltip>
              ) : (
                component.description
              )}
            </div>
          </div>
          {component.uses?.managementCredentials ? (
            <div className="buttonContainerTooltip">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.1rem',
                  marginTop: '0',
                }}
              >
                <AppsIcon />
                <strong> Application</strong>
              </div>
            </div>
          ) : (
            <div className="buttonContainerTooltip">
              {' '}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.1rem',
                  marginTop: '0',
                }}
              >
                <AppsIcon />
                <strong> Application</strong>
              </div>
              <div className="docLinks">
                {component.documentationLink && (
                  <Link target="_blank" href={component.documentationLink}>
                    Documentation
                  </Link>
                )}
                {component.video && (
                  <Link target="_blank" href={component.video}>
                    Video
                  </Link>
                )}
                {component.supportLink && (
                  <Link target="_blank" href={component.supportLink}>
                    Support
                  </Link>
                )}
              </div>
            </div>
          )}
          <div className="buttonContainerFooter">
            {component.state === 'active' ? (
              <Button
                style={{ width: '100%' }}
                className="card-action branded"
                isDisabled={waiting}
                onClick={() => attachEvent(component.application_id)}
                label="Attach"
              ></Button>
            ) : component.state === 'preview' ? (
              <Button
                className="card-action branded"
                isDisabled={waiting}
                onClick={() => attachEvent(component.application_id)}
                label="Attach Preview"
              ></Button>
            ) : (
              <Button
                className="card-action"
                isDisabled
                label={component.state}
              ></Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default DemoComponentCard;
