import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from '../Config';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import StateLabel from '../components/StateLabel';
import DemoTypeLabel from '../components/DemoTypeLabel';
import ErrorMessage from '../components/ErrorMessage';
import IdpTypeGraphic from '../components/IdpTypeGraphic';
import { useNavigate, useLocation } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import { useFlags } from 'flagsmith/react';
import OpportunityDetails from '../components/OpportunityDetails';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  getDemonstration,
  linkDemonstrationToOpp,
  getApplicationInstance,
  getResourceInstance,
  setDemonstrationToOpportunity,
} from '../services/DemoAPI';
import IdpType from '../components/IdpType';
import CollaboratorList from '../components/collaboration/CollaboratorList';
import CollaborationModal from '../components/collaboration/CollaborationModal';
import { useDemoContext } from '../DemoContext';
import { DemonstrationLabel } from '../components/DemonstrationLabel';
import {
  Box,
  BreadcrumbList,
  MenuButton,
  MenuItem,
  CircularProgress,
  Button,
  Card,
  Surface,
  Dialog,
  Tooltip,
  Link,
} from '@okta/odyssey-react-mui';
import LaunchButton from '../components/ui/LaunchButton';
import flagsmith from 'flagsmith';
import Container from '../components/ui/Container/Container';
import Header from '../components/ui/Header/Header';
import Divider from '../components/ui/Divider/Divider';
import Image from '../components/ui/Image/Image';
import CardGroup from '../components/ui/CardGroup/CardGroup';
import {
  AddIcon,
  AppsIcon,
  CheckIcon,
  CloseIcon,
  ExternalLinkIcon,
} from '@okta/odyssey-react-mui/icons';
import './DemoDetails.css';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import ScienceIcon from '@mui/icons-material/Science';
import WebhookIcon from '@mui/icons-material/Webhook';
import { DataView } from '@okta/odyssey-react-mui/labs';
const DemoDetails = () => {
  let params = useParams();
  const demoContext = useDemoContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [demo, setDemo] = useState();
  const [idp, setIDP] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const insightsDescription =
    "Showcase CIC's ability to integrate with log streaming services. In this app, you'll be able to show security personas how CIC can surface security event data.";
  const flags = useFlags([
    'resources',
    'insights_static_resource',
    'mobile_experience_portal_beta_static_app',
    'one_click_guide_page',
    'collaboration',
  ]);

  const getDemo = useCallback(async () => {
    setError();
    if (isAuthenticated) {
      getDemonstration(await getAccessTokenSilently(), params.demoName)
        .then(async (response) => {
          setDemo(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setDemo();
    }
  }, [getAccessTokenSilently, isAuthenticated, setDemo, params.demoName]);

  const getIdp = useCallback(async () => {
    if (isAuthenticated) {
      if (demo) {
        axios
          .get(Config.resourceServer.demoAPI + '/idp/' + demo.idp_id, {
            headers: {
              Authorization: 'Bearer ' + (await getAccessTokenSilently()),
            },
          })
          .then((response) => {
            setIDP(response.data);
          })
          .catch((err) => {
            setError(err);
          });
      }
    } else {
      setDemo();
    }
  }, [demo, getAccessTokenSilently, isAuthenticated]);

  //handles incoming idp and demo info when routed from a create
  useEffect(() => {
    if (state?.idp) {
      setIDP(state.idp);
    }
    if (state?.demo) {
      setDemo(state.demo);
    }
  }, [state]);

  useEffect(() => {
    if (!idp) {
      getIdp();
    }
    if (idp && idp.state !== 'active') {
      var handle = setTimeout(() => getIdp(), 5000);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [idp, getIdp]);

  useEffect(() => {
    if (!demo) {
      getDemo();
    }
    if (demo && demo.state !== 'active' && demo.state !== 'error') {
      var handle = setTimeout(() => getDemo(), 5000);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [demo, getDemo, getAccessTokenSilently]);

  async function deleteDemo() {
    setWaiting(true);
    axios
      .delete(Config.resourceServer.demoAPI + `/demonstration/${demo.name}`, {
        headers: {
          Authorization: 'Bearer ' + (await getAccessTokenSilently()),
        },
      })
      .then((response) => {
        demoContext.refreshContext();
        navigate('/');
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }
  async function linkOpportunity(oppId) {
    setWaiting(true);
    linkDemonstrationToOpp(await getAccessTokenSilently(), demo.name, oppId)
      .then((response) => {
        response.data.app_instances.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setDemo(response.data);
        setWaiting(false);
      })
      .catch((err) => {
        setWaiting(false);
      });
  }

  function handleOnClick(path) {
    navigate(path);
  }

  const dataViewGetData = ({ search, filters }) => {
    let demoCopy = structuredClone(demo);
    demoCopy.resource_instances.forEach(
      (resource) => (resource['componentType'] = 'resource')
    );
    demoCopy.app_instances.forEach(
      (application) => (application['componentType'] = 'application')
    );
    let data;
    if (flags.resources.enabled || flags.insights_static_resource.enabled) {
      data = [...demoCopy.resource_instances, ...demoCopy.app_instances];
      if (
        idp &&
        idp.type === 'customer-identity' &&
        flags.insights_static_resource.enabled
      ) {
        data.push({ label: 'Insights', componentType: 'resource' });
      }
    } else {
      data = [...demoCopy.app_instances];
    }

    if (flags.mobile_experience_portal_beta_static_app.enabled) {
      data.push({
        label: 'Mobile Experience Portal',
        componentType: 'application',
      });
    }
    if (search && search.length > 0) {
      const lowerCaseSearch = search.toLowerCase();

      data = data.filter((row) =>
        Object.values(row).some((value) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(lowerCaseSearch);
          }
          return false;
        })
      );
    }
    function filterData(data, filters) {
      return data.filter((item) => {
        return filters.every((filter) => {
          if (!filter.value || filter.value.length === 0) {
            return true;
          }
          const selectedValues = filter.value.map((v) => v.value);
          const itemValue = item[filter.id];
          return selectedValues.includes(itemValue);
        });
      });
    }
    if (filters && data.length) {
      return filterData(data, filters);
    }
    return data.sort((a, b) => a.label.localeCompare(b.label));
  };
  return (
    <Surface>
      <BreadcrumbList>
        <NavigationButton destination={`/`} msg="Demos" />
        <i href="">{demo ? demo.label : params.demoName}</i>
      </BreadcrumbList>
      {demo && idp ? (
        <Box>
          <div>
            <div className="componentHeader">
              <div>
                <DemonstrationLabel demo={demo} />
                <CollaboratorList
                  entity={demo}
                  setOpen={setOpen}
                  includeManage={flags.collaboration?.enabled}
                />
                <CollaborationModal
                  entity={demo}
                  open={open}
                  setOpen={setOpen}
                  entityType="demonstration"
                  function_reloaddata={getDemo}
                ></CollaborationModal>
              </div>
              <div>
                {waiting ? <CircularProgress active inline /> : null}
                {
                  <>
                    {' '}
                    <MenuButton
                      buttonLabel="Manage"
                      buttonVariant="secondary"
                      onClick={() => setDeleteModalOpen(true)}
                      menuAlignment="right"
                      isDisabled={
                        waiting ||
                        (demo.state !== 'active' && demo.state !== 'error') ||
                        idp.state === 'queued'
                      }
                    >
                      {flagsmith.getTrait('connection') === 'employee' &&
                        demo.type !== 'opportunity' && (
                          <MenuItem
                            onClick={() => {
                              setWaiting(true);
                              getAccessTokenSilently().then((at) =>
                                setDemonstrationToOpportunity(
                                  at,
                                  demo.name
                                ).then((response) => {
                                  setDemo(response.data);
                                  demoContext.refreshContext();
                                  setWaiting(false);
                                })
                              );
                            }}
                          >
                            Link Opportunity
                          </MenuItem>
                        )}
                      <MenuItem onClick={() => setDeleteModalOpen(true)}>
                        Delete
                      </MenuItem>
                    </MenuButton>
                    <Dialog
                      onClose={() => setDeleteModalOpen(false)}
                      title={`Are you sure you want to delete ${demo.name}`}
                      onOpen={() => setDeleteModalOpen(true)}
                      isOpen={deleteModalOpen}
                      primaryCallToActionComponent={
                        <Button
                          variant="danger"
                          onClick={() => {
                            setDeleteModalOpen(false);
                            deleteDemo();
                          }}
                          label="Delete"
                          startIcon={<CheckIcon />}
                        ></Button>
                      }
                      secondaryCallToActionComponent={
                        <Button
                          onClick={() => setDeleteModalOpen(false)}
                          label="Cancel"
                          variant="secondary"
                          startIcon={<CloseIcon />}
                        ></Button>
                      }
                    >
                      This action is permanent and can’t be undone.
                      {idp && idp.type === 'customer-identity' ? (
                        <div>
                          If the identity solution was provisioned by the demo
                          platform it may be destroyed.
                        </div>
                      ) : null}
                    </Dialog>
                  </>
                }
              </div>
            </div>
          </div>

          {demo.type === 'opportunity' ? (
            <OpportunityDetails
              linkedOpportunities={demo.linked_opportunities}
              function_linkOpportunity={linkOpportunity}
              allowEdit={!waiting}
            />
          ) : (
            <DemoTypeLabel type={demo.type} />
          )}

          <Divider className="noTopMargin" />
        </Box>
      ) : (
        <Header className="contentHeader">
          Demonstration: {params.demoName}
        </Header>
      )}
      {error ? <ErrorMessage error={error} retryAction={getDemo} /> : null}
      {demo ? (
        <Container>
          <Container>
            <Header as="h2">Identity Cloud</Header>
            <Container>
              {idp ? (
                <CardGroup itemsPerRow={3}>
                  <Card
                    title={<IdpType idp={idp} />}
                    description={
                      <>
                        {' '}
                        <IdpTypeGraphic type={idp.type} />
                        <StateLabel state={idp.state} />
                        {idp.type === 'customer-identity' &&
                          idp.invite_link && (
                            <div>
                              {idp.meta_data.created_by === user.email ? (
                                <span>
                                  Be sure to 'Accept Invitation' before
                                  launching your tenant.
                                </span>
                              ) : (
                                <span>
                                  Ensure you have been invited to this tenant.
                                </span>
                              )}
                            </div>
                          )}
                      </>
                    }
                    button={
                      <div className="buttonContainerFooter">
                        <Button
                          className="branded"
                          startIcon={<ExternalLinkIcon />}
                          onClick={() => window.open(idp.dashboard)}
                          isDisabled={idp.state !== 'active'}
                          label="Launch"
                        ></Button>
                        {idp.type === 'customer-identity' &&
                          idp.invite_link &&
                          idp.meta_data.created_by === user.email && (
                            <Button
                              className="branded"
                              onClick={() => window.open(idp.invite_link)}
                              label="Accept Invitation"
                            ></Button>
                          )}
                      </div>
                    }
                  ></Card>
                </CardGroup>
              ) : (
                <div className="skeletonContainer">
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={300} height={250} />
                  </Stack>
                </div>
              )}
            </Container>
            <div className="titleAndButtonContainer">
              <Header as="h2">Components</Header>
              <Button
                isDisabled={demo.state !== 'active'}
                className="createActionButton branded"
                label="Add component"
                startIcon={<AddIcon />}
                labelPosition="left"
                onClick={() => handleOnClick(`/demo/${demo.name}/components`)}
              />
            </div>

            {demo && (
              <div style={{ marginTop: '1rem' }}>
                <DataView
                  getData={dataViewGetData}
                  availableLayouts={['grid']}
                  hasSearch
                  hasFilters
                  filters={[
                    {
                      id: 'componentType',
                      label: 'Component Type',
                      variant: 'multi-select',
                      options: [
                        {
                          label: 'Application',
                          value: 'application',
                        },
                        {
                          label: 'Resource',
                          value: 'resource',
                        },
                      ],
                    },
                  ]}
                  cardLayoutOptions={{
                    itemProps: (row) => ({
                      maxGridColumns: 4,

                      title: (
                        <div>
                          {row.label}
                          {row.logo && (
                            <div>
                              <Image
                                floated="right"
                                style={{ float: 'right' }}
                                size="tiny"
                                src={row.logo}
                              />
                            </div>
                          )}
                        </div>
                      ),
                      children: (
                        <div>
                          {row.componentType === 'application' ? (
                            row.label === 'Mobile Experience Portal' ? (
                              <>
                                {' '}
                                Provides an emulated mobile experience that can
                                be shared in your browser.
                                <span>
                                  <ScienceIcon /> Beta
                                </span>
                                <div className="buttonContainerFooter">
                                  <Button
                                    className="branded"
                                    label="Launch"
                                    startIcon={<ExternalLinkIcon />}
                                    onClick={() =>
                                      window.open(
                                        'https://mobile.oktademo.engineering/'
                                      )
                                    }
                                  ></Button>
                                </div>
                              </>
                            ) : (
                              <>
                                {' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <StateLabel state={row.state} />
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '0.5rem',
                                    }}
                                  >
                                    <AppsIcon />
                                    <strong> Application</strong>
                                  </div>
                                </div>
                                <div className="buttonContainerFooter">
                                  <LaunchButton
                                    url={
                                      row.launchUrl ? row.launchUrl : row.link
                                    }
                                  />
                                  <Button
                                    className=""
                                    label="Manage"
                                    onClick={() =>
                                      handleOnClick(
                                        `/demo/${demo.name}/apps/${row.applicationId}`
                                      )
                                    }
                                  ></Button>
                                </div>
                              </>
                            )
                          ) : row.label === 'Insights' ? (
                            <>
                              <div className="buttonStyleOverflow">
                                <Tooltip
                                  placement="top"
                                  text={insightsDescription}
                                >
                                  {insightsDescription.substring(0, 80)}{' '}
                                  <Button
                                    variant="secondary"
                                    size="small"
                                    style={{ padding: 0 }}
                                    startIcon={<MoreHorizIcon />}
                                  ></Button>
                                </Tooltip>
                              </div>
                              <div className="buttonContainerFooter">
                                <Button
                                  className="branded"
                                  onClick={() =>
                                    window.open(
                                      'https://insights.demo.okta.com/'
                                    )
                                  }
                                  label="Launch"
                                ></Button>
                              </div>
                            </>
                          ) : (
                            <>
                              {' '}
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: '0.5rem',
                                }}
                              >
                                <StateLabel state={row.state} />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <WebhookIcon />
                                  <strong> Resource</strong>
                                </div>
                              </div>
                              <span className="ComponentMetaRow">
                                {row.documentationLink && (
                                  <Link
                                    href={row.documentationLink}
                                    target="_blank"
                                  >
                                    Documentation
                                  </Link>
                                )}
                                {row.video && (
                                  <Link href={row.video} target="_blank">
                                    Video
                                  </Link>
                                )}
                                {row.supportLink && (
                                  <Link target="_blank" href={row.supportLink}>
                                    Support
                                  </Link>
                                )}
                              </span>
                              <div className={'buttonContainerFooter'}>
                                {row.launchUrl && (
                                  <>
                                    {row.deploymentData?.launchUriIsFlows ? (
                                      <LaunchButton
                                        className="cta"
                                        url={row.launchUrl}
                                        label="Flows"
                                      />
                                    ) : row.deploymentData
                                        ?.launchUriIsDownload ? (
                                      <LaunchButton
                                        className="cta"
                                        url={row.launchUrl}
                                        label="Download"
                                      />
                                    ) : (
                                      <LaunchButton url={row.launchUrl} />
                                    )}
                                  </>
                                )}
                                <Button
                                  className=""
                                  style={{ width: '100%' }}
                                  onClick={() =>
                                    handleOnClick(
                                      `/demo/${demo.name}/resources/${row.resourceId}`
                                    )
                                  }
                                  label="Manage"
                                ></Button>
                              </div>
                            </>
                          )}
                        </div>
                      ),
                    }),
                  }}
                />
              </div>
            )}
          </Container>
        </Container>
      ) : (
        !error && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={300} height={250} />
              </Stack>
            </div>
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={45} height={40} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={400} height={40} />
              </Stack>
            </div>
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
            </div>
          </div>
        )
      )}
    </Surface>
  );
};
export default DemoDetails;
